import React, {useRef, useCallback} from 'react';
import {Pressable} from 'react-native';

import AddCircleIcon from '~/images/md-icons/add_circle/materialsymbolsoutlined/24px.svg';

const UploadAttachment = ({style, onAttached}) => {
  const fileRef = useRef(null);

  const handleFile = useCallback(
    (e) => {
      e.persist();
      const fileList = e.target.files;

      if (fileList.length) {
        const attachment = fileList[0];

        const reader = new FileReader();
        reader.onload = function (event) {
          let data = event.target.result;

          if (data.startsWith('data:image/')) {
            const img = new Image();
            img.src = data;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const aspect = img.width / img.height;
              canvas.width = Math.min(1024, img.width);
              canvas.height = Math.min(1024, img.height) / aspect;

              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              data = canvas.toDataURL('image/png');

              canvas.remove();

              onAttached({
                is_image: true,
                name: attachment.name,
                data,
              });

              img.remove();
            };
          } else {
            onAttached({is_image: false, name: attachment.name, data});
          }

          e.target.value = '';
        };

        reader.readAsDataURL(attachment);
      }
    },
    [onAttached],
  );

  return (
    <Pressable style={style} onPress={() => fileRef.current?.click()}>
      <AddCircleIcon fill="#4a4a49" />
      <input
        ref={fileRef}
        style={{display: 'none'}}
        type="file"
        accept="image/*,application/pdf"
        onInput={handleFile}
      />
    </Pressable>
  );
};

export default UploadAttachment;
